<template>
    <div id="scrollDown" @click="scroll" :class="show"></div>    
</template>

<script>
export default {
    name: 'scroll-down',
    props:
    {
        targetId: String,
    },
    data()
    {
        return {
            show: 'show'
        }
    },
    mounted()
    {
        let _this = this
        window.addEventListener('scroll', function(){
            if(window.top.scrollY < 50)
            {
                _this.show = 'show'
            }
            else
            {
                _this.show = 'hide'
            }
        })
    },
    methods:
    {
        scroll()
        {
            document.getElementById(this.targetId).scrollIntoView({behavior: "smooth"})
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    #scrollDown
    {
        $size: 60px;

        position:fixed;
        bottom:30px;
        left:0;
        right:0;
        margin:0 auto;
        width:$size;
        height:$size;
        z-index:999999;
        cursor:pointer;
        background:$secondary;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: $box-shadow;
        transition:$transition-base;
        animation-name: bounce;
        animation-duration: .75s;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;

        &.hide
        {
            opacity:0;
            bottom:0;
            transform:translateY(-100%);
        }

        &:before
        {
            content:"";
            position:absolute;
            left:0;
            right:0;
            margin:auto;
            width:2px;
            height:$size/3;
            transform-origin:bottom center;
            transform:rotate(45deg) translateY(1px);
            background:$white;
        }
        &:after
        {
            content:"";
            position:absolute;
            left:0;
            right:0;
            margin:auto;
            width:2px;
            height:$size/3;
            transform:rotate(-45deg) translateY(1px);
            transform-origin:bottom center;
            background:$white;
        }
        @include media-breakpoint-up(lg)
        {
            bottom:120px;
        }

        @keyframes bounce {
            0%, 100% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(-15px);
            }
        }
    }
</style>