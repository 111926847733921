var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-modal",
          rawName: "v-b-modal.edenredLogin",
          modifiers: { edenredLogin: true },
        },
      ],
      staticClass: "edenredLoginModal",
      on: {
        click: function ($event) {
          return _vm.trackEtcSso()
        },
      },
    },
    [
      _vm.textOnly !== ""
        ? _c("div", { staticClass: "textOnly" }, [
            _vm._v("\n        " + _vm._s(_vm.textOnly) + "\n    "),
          ])
        : _vm.logoLarge
        ? _c(
            "div",
            [
              _vm.hasButton == ""
                ? _c("SvgEdenred")
                : _c("b-button", { attrs: { variant: _vm.btnVariant } }, [
                    _vm._v(_vm._s(_vm.hasButton)),
                  ]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "small-logo" },
            [_c("SvgEdenred"), _c("span", [_vm._v("Ticket Compliments")])],
            1
          ),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            id: "edenredLogin",
            title: "Login Edenred",
            "hide-footer": "",
          },
        },
        [_c("iframe", { attrs: { src: this.$config.endpoints.etcSso } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }