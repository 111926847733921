<template>
    <div class="edenredLoginModal" v-b-modal.edenredLogin @click="trackEtcSso()" >
        <div v-if="textOnly !== ''" class="textOnly">
            {{textOnly}}
        </div>
        <div v-else-if="logoLarge">
            <SvgEdenred v-if="hasButton == ''"/>
            <b-button v-else :variant="btnVariant">{{hasButton}}</b-button>
        </div>
        <div v-else class="small-logo" >
            <SvgEdenred />
            <span>Ticket Compliments</span>
        </div>
        <b-modal size="lg" id="edenredLogin" title="Login Edenred" hide-footer>
            <iframe :src="this.$config.endpoints.etcSso" />
        </b-modal>
    </div>
</template>


<script>
import SvgEdenred from '../../svg/etc-logo-transparent'

export default {
    name: 'edenredLoginModal',
    components:
    {
        SvgEdenred,
    },
    props:
    {
        logoLarge:
        {
            type: Boolean,
            default: true,
        },
        hasButton: {
            type: String,
            default: '',
        },
        btnVariant:
        {
            type: String,
            default: 'secondary',
        },
        textOnly:
        {
            type: String,
            default: ''
        },
    },
    computed:{
    },
    methods:
    {
        onIframeMessage(message) {
            
            // il messaggio può non contenere un campo data
            if(message.data == undefined) return

            // il messaggio può contenere un campo data che per coincidenza arriva da altre componenti
            if(message.data.from == undefined) return

            if(message.data.from == 'loginEdenred')
            {
                //this.$bvModal.hide('edenredLogin')
                //window.console.log('ESITO LOGIN EDENRED')
                //window.console.log(message.data)
                //window.console.log(message.data.data.redirectTo)
                window.location.href = '/?token='+message.data.data.token
                //window.location.href = message.data.data.redirectTo
            }
        },
        trackEtcSso(){
            //if(window.LogRocket) window.LogRocket.track('etc_sso')
        },
    },
    mounted()
    {
        this.$bvModal.hide('edenredLogin')
        window.addEventListener('message', this.onIframeMessage)
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .edenredLoginModal
    {
        display:flex;
        min-width:100%;
        min-height:100%;
        align-items:center;
        justify-content:center;
        cursor:pointer;
        iframe
        {
            width:100%;
            height:100%;
        }
        svg
        {
            cursor:pointer;
            width: auto;
            height: auto;
        }

        .textOnly
        {
            text-decoration: underline;
            font-weight: bold;
        }

        .small-logo
        {
            width:100%;
            img
            {
                width: 60px;
                height: auto;
            }

            *
            {
                color:#162056;
                font-size: 14px;
                font-weight:bold;
            }
            display:flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    #edenredLogin
    {
        .modal-content 
        {
            height: 90vh;
            max-height: 600px;
        }
        iframe
        {
            width:100%;
            height:100%;
            border:0;
            padding:0;
            margin:0;
        }
    }
</style>